<template>
  <div class="Layout">
    <!-- 顶部导航栏 -->
    <div class="top">
      <van-nav-bar
        :title="title"
        left-text="返回"
        left-arrow
        fixed
        placeholder
        safe-area-inset-top
        @click-left="$router.back()"
      >
        <template
          #right
          v-if="title !== '消息中心'"
        >
          <van-icon
            name="search"
            @click="clickHandler"
            v-if="$route.path === '/'"
          />
          <van-popover
            v-model="showPopover"
            trigger="click"
            placement="bottom-end"
            overlay
            :offset="[15, 8]"
          >
            <van-sidebar
              :activeKey="activeKey"
              class="mySidebar"
              @change="sidebarChange"
            >
              <!-- <van-sidebar-item badge="5" @click="pendingHandler" disabled>
                <template #title>
                  <van-icon name="todo-list-o"></van-icon> <span>待处理</span>
                </template>
</van-sidebar-item> -->
              <van-sidebar-item
                :badge="count.unreadMessage"
                to="/message"
                @click="newMessageHandler"
              >
                <template #title>
                  <svg-icon
                    icon-class="newMessage"
                    class="van-icon"
                  />
                  <!-- <van-icon name="comment-circle-o"></van-icon> -->
                  <span>新消息</span>
                </template>
              </van-sidebar-item>
              <!-- <van-sidebar-item title="新消息" badge="99+" to="/message"/> -->
            </van-sidebar>
            <template #reference>
              <van-icon
                name="ellipsis"
                :dot="!!count.unreadMessage"
              />
            </template>
          </van-popover>
        </template>
      </van-nav-bar>
    </div>
    <!-- 内容展示 -->
    <div class="info">
      <keep-alive>
        <router-view
          v-if="$route.meta.isKeepAlive"
          v-watermark
        ></router-view>
      </keep-alive>
      <router-view
        v-if="!$route.meta.isKeepAlive"
        v-watermark
      ></router-view>
    </div>
    <!-- 底部导航栏 -->
    <div class="bottom">
      <van-tabbar
        safe-area-inset-bottom
        fixed
        route
        placeholder
      >
        <van-tabbar-item
          icon="chat-o"
          to="/message"
          :badge="count.unreadMessage"
        >消息</van-tabbar-item>
        <van-tabbar-item
          icon="phone-o"
          to="/addressBook"
        >通讯录</van-tabbar-item>
        <van-tabbar-item
          icon="apps-o"
          to="/"
        >工作台</van-tabbar-item>
        <van-tabbar-item
          icon="user-o"
          to="/my"
        >我</van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'Layout',
  data() {
    return {
      title: '',
      showPopover: false

    }
  },
  computed: {
    ...mapState('user', ['activeKey']),
    ...mapState('count', ['count'])
  },
  methods: {
    ...mapMutations('user', ['updateActiveKey']),
    // 点击搜索
    clickHandler() {
      this.$toast('该功能暂未开放!')
      // this.$router.push('/userSearch')
    },
    // 跳转待处理
    pendingHandler() {
      this.showPopover = false
    },
    // 跳转消息
    newMessageHandler() {
      this.showPopover = false
      this.$router.push('/message')
    },
    // 顶部导航变化
    sidebarChange(index) {
      this.updateActiveKey(index)
    }
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.path === '/') {
          this.title = '工作台'
        } else if (val.path === '/my') {
          this.title = '我的设置'
        } else if (val.path === '/addressBook') {
          this.title = '通讯录'
        } else {
          this.title = '消息中心'
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.Layout {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  ::v-deep {
    .info {
      overflow: hidden;
      // height: calc(100% - 46px - 50px);
      flex: 1;
      width: 100%;
    }

    .van-icon-search {
      margin-right: 10px;
      font-size: 20px;
    }

    .van-icon-ellipsis {
      font-size: 20px;
    }
  }
}
</style>
